import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import CircleMinus from '../CircleMinus';
import CirclePlus from '../CirclePlus';

// Styles
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { space, color } = theme;

const CateringPackage = styled.div`
  margin-bottom: ${space.space};
`;

const AccordionPanelContainer = styled.div`
  padding: 1.15em 0;
  border-bottom: 1px solid ${color.callout};
  width: 100%;

  .title {
    margin-bottom: 0;
  }
`;

const AccordionPanelHead = styled.div`
  ${mixins.flexBetween};
`;

const AccordionPanelButton = styled.button`
  background: none;
  border: none;
  -webkit-appearance: none;
  padding: ${space.halfSpace};
`;

const AccordionPanelContent = styled.div`
  p {
    margin-bottom: ${space.halfSpace};
  }
`;

function CateringPackages() {
  const [collapsed, expanded] = useState(true);

  const handleClick = () => (collapsed ? expanded(false) : expanded(true));

  return (
    <AccordionPanelContainer>
      <AccordionPanelHead>
        <div className="title">Catering Packages</div>
        <AccordionPanelButton onClick={handleClick}>
          <CirclePlus collapsed={collapsed} />
          <CircleMinus collapsed={collapsed} />
        </AccordionPanelButton>
      </AccordionPanelHead>
      <AccordionPanelContent className={collapsed ? 'collapsed' : 'expanded'}>
        <CateringPackage>
          <h5>Poco Signature</h5>
          <p>
            Hummous, Fresh Pita Bread, Fatoosh, Grape Leaves, Shish Tawook, and
            Poco Rice &mdash; <span>17 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Poco Kabobs</h5>
          <p>
            Hummous, Fresh Pita Bread, Fatoosh, Beef Tenderloin Shish Kabob,
            Shish Tawook, and Poco Rice &mdash; <span>20 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Poco Mix</h5>
          <p>
            Hummous, Fresh Pita Bread, Fatoosh, Grape Leaves, Ara-yes Halabi,
            Beef Tenderloin Shish Kabob, Shish Tawook, and Poco Rice &mdash;{' '}
            <span>26 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Poco Mezza</h5>
          <p>
            Hummous, Fresh Pita Bread, Feta Dip, Fatoosh, Tabouli, Grape Leaves,
            Ara-yes, Chicken Wraps, Beef Tenderloin Shish Kabob, Shish Kafta,
            Shish Tawook, and Poco Rice &mdash; <span>45 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Poco Experience</h5>
          <p>
            Hummous, Fresh Pita Bread, Feta Dip, Fatoosh, Grape Leaves, Brussel
            Sprouts, Cauliflower, Sauteed Mushrooms, Lamb Chops, Beef Tenderloin
            Shish Kabob, Shish Tawook, and Poco Rice &mdash;{' '}
            <span>50 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Deluxe Package</h5>
          <p>
            Hummous, Fresh Pita Bread, Feta Dip, Vegetable Tray, Fatoosh, Greek
            Salad, Grape Leaves, Brussel Sprouts, Cauliflower, Sauteed
            Mushrooms, Greek Potatoes, Lamb Chops, Whole Beef Tenderloin Filet,
            and Poco Rice &mdash; <span>55 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Vegetarian Package</h5>
          <p>
            Hummous, Fatoosh, Veggie Grape Leaves, Brussel Sprouts, Cauliflower,
            Mujadara, Sauteed Mushrooms, Veggie Shish Kabob, and Poco Rice
            &mdash; <span>25 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Gluten-Free Package</h5>
          <p>
            Hummous, Gluten-Free Bread, Greek Salad, Grape Leaves, and Shish
            Tawook &mdash; <span>20 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Slider Package</h5>
          <p>
            Grilled Lamb Sliders, Chicken Parmesan Sliders, Hummous, Fresh Pita
            Bread, and Fatoosh &mdash; <span>18 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Petite Dessert Platter</h5>
          <p>
            Reese's Pieces Brownie, New York Cheesecake with Fresh Fruit, Carrot
            Cake with Cream Cheese Frosting. Choice of two.
          </p>
        </CateringPackage>
        <CateringPackage>
          <p>
            * Packages are intended to be guides. Any and all items may be
            substituted and are sugject to price changes. All packages are
            served with pita bread and rice. All regular menu items are
            available for pricing and other packages are available upon request
            <span />
          </p>
        </CateringPackage>
      </AccordionPanelContent>
    </AccordionPanelContainer>
  );
}

export default CateringPackages;
