// import React from 'react';
// import styled from 'styled-components';

// // Components
// import PocoCrown from '../PocoCrown/index';

// // Styles
// import media from '../../styles/media';
// import theme from '../../styles/theme';

// const { color, border } = theme;

// const CalloutContentContainer = styled.div`
//   align-items: center;
//   background-color: ${color.foreground};
//   border-radius: ${border.radius};
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   padding: 6em 2em;

//   @media ${media.smallPhone} {
//     padding: 3.5em 2em;
//   }

//   h2 {
//     text-align: center;
//   }

//   p,
//   p.large {
//     text-align: center;
//     margin-bottom: 0;
//   }
// `;

// function SangriaCallout() {
//   return (
//     <section>
//       <div className="wrap">
//         <CalloutContentContainer>
//           <PocoCrown />
//           <h2 className="dark">Famous Sangria</h2>
//           <p className="large dark">
//             <em className="dark">$10 pitchers</em>
//           </p>
//           <p>Every Thursday from 3 to 7.</p>
//         </CalloutContentContainer>
//       </div>
//     </section>
//   );
// }

// export default SangriaCallout;

import React from 'react';
import styled from 'styled-components';

// Styles
import media from '../../styles/media';
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { border, color } = theme;

const SangriaCalloutContainer = styled.section``;

const SangriaCalloutContainerImage = styled.div``;

const SangriaCalloutGrid = styled.div`
  ${mixins.wrap}
  ${mixins.grid}
	border: 1px solid ${color.typeDim};
  border-radius: ${border.radius};
  position: relative;
  overflow: hidden;
  z-index: 1;
`;

const SangriaCalloutGridContent = styled.div`
  backdrop-filter: blur(25px);
  padding: 6em;

  @media ${media.primary} {
    padding: 3em;
  }

  @media ${media.secondary} {
    padding: 2.65em;
  }

  @media ${media.smallPhone} {
    padding: 2em;
  }

  p,
  p.large {
    color: ${color.foreground};
    margin-bottom: 0;
  }
`;

const GridImage = styled.div`
  background-image: url(https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-generic-sangria-glass.webp)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media ${media.secondary} {
		padding-top: 75%;
  }
`;

function SangriaCallout() {
  return (
    <SangriaCalloutContainer>
      <SangriaCalloutContainerImage />
      <SangriaCalloutGrid>
        <SangriaCalloutGridContent>
          <div className="title">Happy Hour</div>
          <h4>Poco's Famous Sangria</h4>{' '}
          <p className="large">
            <em>$11.50 carafes</em>{' '}
          </p>
          <p>Every Thursday and Sunday from 3 to 7.</p>
        </SangriaCalloutGridContent>
        <GridImage />
      </SangriaCalloutGrid>
    </SangriaCalloutContainer>
  );
}

SangriaCallout.propTypes = {};

export default SangriaCallout;
