const CateringImagesData = [
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/catering/poco-piatti-catering-arayes-halabi.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/catering/poco-piatti-catering-beef-kabob.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/catering/poco-piatti-catering-hummus.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/catering/poco-piatti-catering-kabobs.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/catering/poco-piatti-catering-mushrooms.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/catering/poco-piatti-catering-shish-tawook.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/catering/poco-piatti-catering-salads.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/catering/poco-piatti-catering-table.webp',
  },
];

export default CateringImagesData;
