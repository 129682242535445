const vegetarianData = [
  {
    name: "Riad's Hummuos B Tahini",
    desc: 'Served with Pita.',
    price: {
      small: 6,
      large: 10,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your server for fresh veggies.',
    },
  },
  {
    name: 'Roasted Garlic Feta Dip',
    desc: 'Served warm with diced tomato.',
    price: {
      small: 7,
      large: 12,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your server for fresh veggies.',
    },
  },
  {
    name: 'Lebanee',
    desc: 'Yogurt with cucumber, mint and drizzled with olive oil.',
    price: {
      small: 5,
      large: 8,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your server for fresh veggies.',
    },
  },
  {
    name: 'Saganaki Kasseri',
    desc: 'Greek kasseri cheese flamed tableside.',
    price: 7,
  },
  {
    name: 'Sauteed Mushrooms ',
    price: 10,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Mujadra',
    desc: 'Green lentils and rice with caramelized onions and pickles.',
    price: {
      small: 7,
      large: 11,
    },
  },
  {
    name: 'Lubia Bi Zeit',
    desc: 'Sauteed green beans with garlic and onions in a tomato sauce.',
    price: 9,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Veggie Grape Leaves',
    desc: 'Stuffed with chick peas, tomatoes, rice, onion and lemon.',
    price: {
      small: 5,
      large: 9,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Fried Brussel Sprouts',
    desc: 'Apricot, pecans, arugula and feta.',
    price: 12,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Roasted Cauliflower',
    desc: 'Tossed in garlic oil, tahini and fresh dill.',
    price: {
      small: 7,
      large: 12,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Stuffed Portabella Caps',
    desc: 'Mushroom stuffed with a broccoli pesto and topped with pepperjack cheese and tomato.',
    price: {
      small: 7,
      large: 13,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Eggplant Rolotini',
    desc: 'Baked eggplant stuffed with ricotta. Topped with marinara and our three cheese blend.',
    price: 11,
  },
];

export default vegetarianData;
