import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Components
import Logo from '../Logo/index';
import Subfooter from '../Subfooter/index';
import Icon from '../Icon/index';

// Styles
import theme from '../../styles/theme';
import mixins from '../../styles/mixins';
import media from '../../styles/media';

const { color, space, font } = theme;

const FooterContainer = styled.footer`
  padding: 6em 0;
  position: relative;

  @media ${media.secondary} {
    padding: 3em 0 0;
  }
`;

const FooterPrimaryContent = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  @media ${media.primary} {
    flex-direction: column;
    margin-bottom: 3em;
  }
`;

const LogoContainer = styled(Link)`
  @media ${media.primary} {
    margin-bottom: ${space.space};
  }
`;

const SocialsContainer = styled.div``;

const StyledWrap = styled.div`
  ${mixins.wrap}
`;

const StyledNav = styled.nav`
  display: inline-block;
  position: relative;
  columns: 2 12em;

  @media ${media.primary} {
    columns: 1 auto;
    margin-bottom: 3.65em;
  }
`;

const StyledLink = styled(Link)`
	${mixins.animate}
	break-inside: avoid;
  color: ${color.foreground};
	display: block;
	font-family: ${font.bold};
	font-size: 1.15em;
	letter-spacing: 0.085em;
	margin-right: 2em;
	padding: 1em 0;
	text-transform: uppercase;

	@media ${media.primary} {
		display: block;
	}

	@media ${media.secondary} {
		font-size: 1em;
	}

	@media ${media.smallPhone} {
		font-size: 0.775em;
	}

	&:hover {
		color: ${color.callout};
	}
}
`;

function Footer() {
  return (
    <>
      <FooterContainer>
        <StyledWrap>
          <FooterPrimaryContent>
            <LogoContainer to="/">
              <Logo light />
            </LogoContainer>
            <StyledNav>
              <StyledLink to="/menu">Menu</StyledLink>
              <StyledLink to="/private-events">Private Events</StyledLink>
              <StyledLink to="/catering">Catering</StyledLink>
              <StyledLink to="/about">About</StyledLink>
              <StyledLink to="/order">Order</StyledLink>
            </StyledNav>
            <SocialsContainer>
              <Icon
                type="facebook"
                href="https://www.facebook.com/PocoPiatti/"
              />
              <Icon
                type="instagram"
                href="https://www.instagram.com/pocopiatti_pb/?hl=en"
              />
            </SocialsContainer>
          </FooterPrimaryContent>
        </StyledWrap>
      </FooterContainer>
      <Subfooter />
    </>
  );
}

export default Footer;
