const GenericImagesData = [
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-hensville-bar.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-sylvania-wine-shelves.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-perrysburg-bar-dining-area.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-sylvania-bar.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-hensville-patio-seating.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-hensville-rooftop-view.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-pita-bread-oven.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-downtown-dining-room.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-sylvania-large-party-table.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-hensville-dining-and-kitchen.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-perrysburg-bar.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-hensville-decor.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-downtown-tables-with-artwork.webp',
  },
];

export default GenericImagesData;
