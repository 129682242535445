// import React from 'react';
import React from 'react';
import styled from 'styled-components';

// Components
import LinkExternal from '../LinkExternal/index';
import LinkInternal from '../LinkInternal/index';
import GradientBottom from '../GradientBottom/index';

// Styles
import media from '../../styles/media';

const HeroImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 685px;
  width: 100vw;
  position: relative;
  z-index: 1;

  @media ${media.smallPhone} {
    min-height: 725px;
  }

  video {
    height: 100vh;
    min-height: 625px;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    filter: brightness(0.65);
    object-fit: cover;

    @media ${media.smallPhone} {
      min-height: 725px;
    }
  }

  .wrap {
    text-align: center;
    z-index: 2;
  }
`;

const HeroNav = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;

  @media ${media.secondary} {
    flex-direction: column;
  }

  a {
    margin-right: 1.25em;

    @media ${media.secondary} {
      display: block;
      margin-bottom: 1.2em;
      width: 100%;
      text-align: center;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

function HomeHero() {
  return (
    <HeroImage>
      <video playsInline autoPlay muted loop id="bgvid">
        <source
          src="https://pocopiatti.s3.us-east-2.amazonaws.com/videos/poco-piatti-videos-small-plates.mp4"
          type="video/mp4"
        />
        <source
          src="https://pocopiatti.s3.us-east-2.amazonaws.com/videos/poco-piatti-videos-small-plates.mp4"
          type="video/mmp4"
        />
      </video>
      <div className="wrap">
        <h1>
          MEDITERRANEAN
          <br />
          <span>Tapas</span>
        </h1>
        <HeroNav>
          <LinkInternal
            text="Order Online"
            title="Place an online order."
            filled
            href="/order"
          />
          <LinkExternal
            text="Carryout"
            href="tel:419-931-0281"
            title="Call 419-931-0281 to place a carryout order."
            light
          />
          <LinkExternal
            text="Reservations"
            href="tel:419-931-0281"
            title="Call 419-931-0281 to make a reservation."
            light
          />
        </HeroNav>
      </div>
      <GradientBottom />
    </HeroImage>
  );
}

export default HomeHero;
