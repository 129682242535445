import React from 'react';
import styled from 'styled-components';

// Components
import HensvilleLogo from '../HensvilleLogo/index';

// Styles
import media from '../../styles/media';
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { border, color } = theme;

const HensvilleCalloutContainer = styled.section`
	background-image: url(https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-hensville-decor.webp)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

	&:before {
		background-color: ${color.background};
		content: "";
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.4;
		width: 100%;
	}
`;

const HensvilleCalloutGrid = styled.div`
  ${mixins.wrap}
  ${mixins.grid}
	background-color: ${color.foreground};
  border-radius: ${border.radius};
  overflow: hidden;
`;

const HensvilleCalloutGridContent = styled.div`
  padding: 6em;

  @media ${media.primary} {
    padding: 3em;
  }

  @media ${media.secondary} {
    padding: 2.65em;
  }

  @media ${media.smallPhone} {
    padding: 2em;
  }

  p,
  p.large {
    margin-bottom: 0;
  }
`;

const GridImage = styled.div`
	${mixins.flexCenter}
  background-image: url(https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-generic-night-ball-game.webp)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media ${media.secondary} {
		padding: 6em 0;
  }
`;

function HensvilleCallout() {
  return (
    <HensvilleCalloutContainer>
      <HensvilleCalloutGrid>
        <HensvilleCalloutGridContent>
          <div className="title dark">Poco Piatti Hensville</div>
          <h4 className="dark">Event Center</h4>
          <p>
            Located in Toledo's Warehouse District (9 N St. Clair St ), Poco
            Piatti Hensville is ready to host your next event! With seating for
            up to 100, this private event space can be reserved and catered by
            Poco Piatti. Contact our{' '}
            <a href="tel:419-931-0281">Catering Manager</a> for questions and
            details!
          </p>
        </HensvilleCalloutGridContent>
        <GridImage>
          <HensvilleLogo />
        </GridImage>
      </HensvilleCalloutGrid>
    </HensvilleCalloutContainer>
  );
}

HensvilleCallout.propTypes = {};

export default HensvilleCallout;

// import React from 'react';
// import styled from 'styled-components';

// // Components
// import HensvilleLogo from '../HensvilleLogo';

// // Styles
// import theme from '../../styles/theme';
// import media from '../../styles/media';

// const { color } = theme;

// const CalloutGrid = styled.div`
//   background-color: ${color.foreground};
//   border-radius: 2em;
//   overflow: hidden;

//   @media ${media.secondary} {
//     display: flex;
//     flex-direction: column;
//   }
// `;

// const CalloutContentContainer = styled.div`
//   align-items: center;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   padding: 6em 3em;

//   @media ${media.smallPhone} {
//     padding: 3.5em 2em;
//   }

//   p,
//   p.large {
//     text-align: center;
//     margin-bottom: 0;
//   }

//   p {
//     max-width: 26em;
//   }
// `;

// function HensvilleCallout() {
//   return (
//     <section>
//       <div className="wrap">
//         <CalloutGrid>
//           <CalloutContentContainer>
// <div className="title dark">New location</div>
// <HensvilleLogo />
// <p className="large dark no-padding-bottom">
//   <em className="dark">Now Open</em>
// </p>
// <p>
//   Located in Toledo's Warehouse District, Poco Piatti Hensville is
//   open Tues through Sat from 5 pm to 10 pm.
// </p>
//           </CalloutContentContainer>
//         </CalloutGrid>
//       </div>
//     </section>
//   );
// }

// export default HensvilleCallout;
