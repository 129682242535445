const locationsData = [
  {
    name: 'Perrysburg',
    addLine1: '3155 Chappel Dr.',
    addLine2: 'Perrysburg, Ohio',
    hrsLine1: 'Sun - Thu, 11:30 - 9:00 pm',
    hrsLine2: 'Fri - Sat, 11:30 - 10:00 pm',
    phone: '(419) 931-0281',
    mapLink:
      'https://www.google.com/maps/place/Poco+Piatti/@41.5266222,-83.6418943,17z/data=!3m1!4b1!4m5!3m4!1s0x883c768b53d781e3:0x5d6db82562378465!8m2!3d41.5266222!4d-83.6397056',
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/Locations/poco-piatti-perrysburg-storefront.webp',
  },
  {
    name: 'Sylvania',
    addLine1: '6710 W. Central Ave #1',
    addLine2: 'Toledo, Ohio',
    hrsLine1: 'Sun - Thu, 11:30 - 9:00 pm',
    hrsLine2: 'Fri - Sat, 11:30 - 10:00 pm',
    phone: '(419) 931-0281',
    mapLink:
      'https://www.google.com/maps/place/Poco+Piatti/@41.6761659,-83.705647,17z/data=!3m1!4b1!4m5!3m4!1s0x883c7c71850970b1:0x7cc2a19d8128c9ee!8m2!3d41.6760799!4d-83.7034321',
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/Locations/poco-piatti-sylvania-storefront.webp',
  },
  {
    name: 'Hensville',
    addLine1: '9 N St. Clair St',
    addLine2: 'Toledo, Ohio',
    hrsLine1: 'Tue - Thur, 4 pm - 9 pm',
    hrsLine2: 'Fri - Sat, 4 pm - 10:00 pm',
    phone: '(419) 931-0281',
    mapLink:
      'https://www.google.com/maps/place/9+N+St+Clair+St,+Toledo,+OH+43604/@41.6475065,-83.5392296,18z/data=!4m13!1m7!3m6!1s0x883b86c546c6e98b:0xdb0803ea00d6a972!2s9+N+St+Clair+St,+Toledo,+OH+43604!3b1!8m2!3d41.647841!4d-83.538058!3m4!1s0x883b86c546c6e98b:0xdb0803ea00d6a972!8m2!3d41.647841!4d-83.538058',
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/Locations/poco-piatti-hensville-storefront.webp',
  },
  {
    name: 'The Garden',
    addLine1: '1521 Front Street',
    addLine2: 'Toledo, Ohio',
    hrsLine1: 'Open Everyday',
    hrsLine2: '11 am - 8 pm',
    phone: '(419) 931-0281',
    mapLink:
      'https://www.google.com/maps/place/The+Garden+by+Poco+Piatti/@41.6560166,-83.5780135,13z/data=!4m10!1m2!2m1!1smarket+hall+glass+city+metropark!3m6!1s0x883b87e97b35be43:0xe94d2d0c3469b284!8m2!3d41.6549826!4d-83.5168161!15sCiBtYXJrZXQgaGFsbCBnbGFzcyBjaXR5IG1ldHJvcGFya1oiIiBtYXJrZXQgaGFsbCBnbGFzcyBjaXR5IG1ldHJvcGFya5IBCnJlc3RhdXJhbnSaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTktlWEpNYUhOUlJSQULgAQA!16s%2Fg%2F11sj5g2cx7?entry=ttu',
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/Locations/poco-piaiit-locations-the-garden-building.webp',
  },
  // {
  //   name: 'Downtown',
  //   addLine1: '329 N Huron',
  //   addLine2: 'Toledo, Ohio',
  //   hrsLine1: 'Open for private events',
  //   phone: '(419) 931-0281',
  //   mapLink:
  //     'https://www.google.com/maps/place/Poco+Piatti/@41.6532212,-83.5387535,17z/data=!3m1!4b1!4m5!3m4!1s0x883b877974002a73:0x42c4c8f36a19c00d!8m2!3d41.6532212!4d-83.5365648',
  //   image:
  //     'https://pocopiatti.s3.us-east-2.amazonaws.com/Locations/poco-piatti-downtown-storefront.webp',
  // },
];

export default locationsData;
