import React from 'react';
import styled from 'styled-components';

// Components
import LinkInternal from '../LinkInternal';
import GradientBottom from '../GradientBottom/index';

// Styles
import theme from '../../styles/theme';

const { color } = theme;

const PrivateEventsCalloutStyle = styled.section`
  background-image: url('https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-tables.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  z-index: 1;

  .wrap {
    z-index: 1;
  }

  p {
    color: ${color.foreground};
  }
`;

const GradientOverlayTop = styled.div`
  top: 0;
  height: 40em;
  left: 0;
  -webkit-mask-image: linear-gradient(
    180deg,
    ${color.foreground},
    rgba(2, 2, 10, 0)
  );
  mask-image: linear-gradient(180deg, ${color.foreground}, rgba(2, 2, 10, 0));
  overflow-x: hidden;
  pointer-events: none;
  position: absolute;
  width: 100%;

  &:before {
    background-color: #02020a;
    top: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }
`;

const PrivateEventsCallout = () => (
  <PrivateEventsCalloutStyle>
    <GradientOverlayTop />
    <div className="wrap">
      <h2>
        <span>Private</span>
        Events
      </h2>
      <p className="large">
        Book your next private party or event at Poco Piatti. We offer{' '}
        <em>flexible packages</em> and personal attention to your party planning
        needs with each event <em>customized</em> to fit your criteria.
      </p>
      <LinkInternal
        text="Learn More"
        href="/private-events"
        title="Learn more about private events at Poco Piatti."
      />
    </div>

    <GradientBottom />
  </PrivateEventsCalloutStyle>
);

export default PrivateEventsCallout;
