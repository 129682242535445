import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import LinkExternal from '../LinkExternal/index';

// Images
import ToastLogo from '../../assets/images/toast-logo.svg';
import GooglePlay from '../../assets/images/google-play.svg';
import AppStore from '../../assets/images/app-store.svg';

// Styles
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';
import media from '../../styles/media';

const { color, border } = theme;

const OrderModalStyle = styled.section`
  .order-modal-container {
    ${mixins.wrap}
    ${mixins.grid}
    border: 1px solid ${color.typeDim};
    border-radius: ${border.radius};
    align-items: center;
    overflow: hidden;

    @media ${media.primary} {
      grid-template-columns: 1fr;
      overflow: hidden;
    }

    &-content {
      padding: 6em;

      @media ${media.primary} {
        padding: 3em;
      }

      @media ${media.secondary} {
        padding: 2.65em;
      }

      @media ${media.smallPhone} {
        padding: 2em;
      }

      h4 {
        margin-bottom: 1.25em;
      }
    }

    &-links {
      @media ${media.primary} {
        padding: 0;
      }
      .cta {
        text-align: center;
        width: 100%;

        margin-bottom: 0.5em;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const GridImage = styled.div`
  background-image: url(https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-roasted-beet-salad.webp)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
	height: 100%;
	width: 100%;
`;

const MobileAppImage = styled.div`
	backdrop-filter: blur(15px);
  background-image: url(https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-generic-iphone-mockup.webp)};
  background-size: contain;
  background-position-y: 22.5em;
	background-position-x: center;
  background-repeat: no-repeat;
	border-radius: 0 ${border.radius} ${border.radius} 0;
	height: 100%;
	padding: 6em 0;
	text-align: center;
	width: 100%;


	@media ${media.primary} {
		padding: 6em 2em 19em;
    background-size: 80%;
		border-radius: 0 0 ${border.radius} ${border.radius};
	}

	.title {
		margin-bottom: 1.65em;
	}

	img {
		width: 12em;
		margin-bottom: 2em;

	}
`;

const AppStoreContainer = styled.div`
  a {
    display: inline-block;
    position: relative;

    &:first-of-type {
      margin-right: 1em;

      @media ${media.primary} {
        margin-right: 0.25em;
      }
    }

    &:last-of-type {
      margin-left: 1em;

      @media ${media.primary} {
        margin-left: 0.25em;
      }
    }

    img {
      height: 3rem;
      width: auto;

      @media ${media.primary} {
        height: 2.25em;
      }

      @media ${media.smallPhone} {
        height: 1.85em;
      }
    }
  }
`;

const OrderModal = ({ modal }) => (
  <OrderModalStyle modal={modal}>
    <div className="order-modal-container">
      <div className="order-modal-container-content">
        <div className="title">Skip the wait</div>
        <h2>Order Online</h2>
        <div className="order-modal-container-links">
          <LinkExternal
            text="Perrysburg"
            href="https://www.toasttab.com/poco-piatti-perrysburg-3155-chapel-drive/v3/?mode=fulfillment"
            target="_blank"
          />
          <LinkExternal
            text="Sylvania"
            href="https://www.toasttab.com/poco-piatti-toledo-6710-w-central-ave-1/v3/?mode=fulfillment"
            target="_blank"
          />
          <LinkExternal
            text="Hensville"
            href="https://www.toasttab.com/poco-piatti-downtown-329-n-huron-st"
            target="_blank"
          />
        </div>
      </div>
      <GridImage>
        <MobileAppImage>
          <div className="title">Order poco on the Toast Takout app</div>
          <img src={ToastLogo} alt="Toast Takout App" />
          <AppStoreContainer>
            <a
              href="https://apps.apple.com/us/app/toast-takeout/id1362180579?ls=1"
              target="_blank"
              title="Get the toast takout app on the Apple App Store"
              rel="noreferrer"
            >
              <img src={AppStore} alt="Download at the Apple App Store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.toasttab.consumer&utm_source=landingpage&utm_campaign=landingpage&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              title="Get the Toast Takeout app on Google Play"
            >
              <img src={GooglePlay} alt="Get it on Google Play" />
            </a>
          </AppStoreContainer>
        </MobileAppImage>
      </GridImage>
    </div>
  </OrderModalStyle>
);

OrderModal.propTypes = {
  modal: PropTypes.bool.isRequired,
};

export default OrderModal;
