import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Header from '../../components/Header/index';
import SubPageHero from '../../components/SubPageHero';
import Faq from '../../components/Faq/index';
import Reviews from '../../components/Reviews';
import Locations from '../../components/Locations/index';
import PrivateEventsCallout from '../../components/PrivateEventsCallout/index';
import Footer from '../../components/Footer/index';

function About() {
  return (
    <>
      <Helmet>
        <title>About | Poco Piatti</title>
        <meta
          name="description"
          content="Mediterranean tapas restaurant and bar."
        />
      </Helmet>
      <Header light absolute />
      <SubPageHero
        title="About"
        h1Text={[
          'A FRESH TAKE ON THE TRADITIONAL',
          <span>dining experience.</span>,
        ]}
        imageUrl="https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-hensville-bar.webp"
        copy="Poco Piatti is a Mediterranean-inspired casual restaurant that serves chef-driven dishes made with the freshest ingredients. Our small plates, made to share, are made with a focus on quality, creativity, and service.  Local craft beers, uniquely-flavored wines, and handcrafted cocktails round out the experience. Our passion for Mediterranean food shines through in every dish. We are dedicated to providing you with a great dining experience at Poco Piatti."
      />
      <Faq />
      <Reviews />
      <Locations />
      <PrivateEventsCallout />
      <Footer />
    </>
  );
}

export default About;
