import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Header from '../../components/Header/index';
import ImageSlider from '../../components/ImageSlider/index';
import PrivateEventsGrid from '../../components/PrivateEventsGrid';
import PrivateEventsInquiryCallout from '../../components/PrivateEventsInquiryCallout';
import Locations from '../../components/Locations/index';
import Footer from '../../components/Footer/index';

// Data
import PrivateEventsImagesData from '../../data/images/private-events-images';
import SubPageHero from '../../components/SubPageHero';

function PrivateEvents() {
  return (
    <>
      <Helmet>
        <title>Private Events | Poco Piatti</title>
        <meta
          name="description"
          content="Mediterranean tapas restaurant and bar."
        />
      </Helmet>
      <Header light absolute />
      <SubPageHero
        h1Text={['LET US MAKE YOUR EVENT', <span>unforgettable</span>]}
        title="Events"
        copy="When considering a venue to host your next event, we believe that it should be as stress free and enjoyable as possible. Allow us to create an experience for you in one of our comfortable private dining rooms or outdoor spaces."
        imageUrl="https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-fruit.webp"
      />
      <PrivateEventsGrid />
      <ImageSlider imageData={PrivateEventsImagesData} />
      <PrivateEventsInquiryCallout />
      <Locations />
      <Footer />
    </>
  );
}

export default PrivateEvents;
