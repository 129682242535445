import React from 'react';
import styled from 'styled-components';

// Components
import SectionHeading from '../SectionHeading';
import PrivateEventsGridCard from './PrivateEventsGridCard';

// Styles
import media from '../../styles/media';
import theme from '../../styles/theme';

const { grid } = theme;

const PrivateEventsGridSection = styled.section``;

const PrivateEventsGridContainer = styled.div`
  display: grid;
  grid-gap: ${grid.gap};
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  z-index: 1;

  @media ${media.secondary} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

function PrivateEventsGrid() {
  return (
    <PrivateEventsGridSection>
      <div className="wrap">
        <SectionHeading
          text={['We have unique spaces for any', <span>celebration!</span>]}
          id="locations"
        />
      </div>
      <div className="wrap">
        <PrivateEventsGridContainer>
          <PrivateEventsGridCard
            heading="Social Events"
            copy="Our custom events are unique and fun. From intimate anniversary dinners to larger social functions, we take great care in planning as well as decoration and setup."
            image="https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-tables.webp"
          />
          <PrivateEventsGridCard
            heading="Corporate Events"
            copy="Our private dining spaces are a unique opportunity for your group to gather and relax in an upscale, yet relaxed environment. Your guests will enjoy the experience of truly experiencing first-class service and cuisine."
            image="https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-corporate-space.webp"
          />
          <PrivateEventsGridCard
            heading="Lunch Events"
            copy="Enjoy a casual lunch in our private dining space, or invite colleagues to meet while enjoying a relaxing afternoon in the lounge."
            image="https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-lunch-event.webp"
          />
          <PrivateEventsGridCard
            heading="Catering"
            copy="Need to set up an event? We'll handle all the details — from ordering food, seating and clean-up, to decor and transportation."
            image="https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-generic-food-truck.webp"
          />
        </PrivateEventsGridContainer>
      </div>
    </PrivateEventsGridSection>
  );
}

export default PrivateEventsGrid;
