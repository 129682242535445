const PrivateEventsImagesData = [
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-table-with-artwork.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-bar.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-fruit.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-hensville-rooftop.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-downtown-dining-area.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-tables-with-artwork.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-tables.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-downtown.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-downtown-windows.webp',
  },
];

export default PrivateEventsImagesData;
