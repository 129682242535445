import React from 'react';
import styled from 'styled-components';

// Components
import LinkExternal from '../LinkExternal';

// Styles
import media from '../../styles/media';
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { border, color } = theme;

const PrivateEventsInquiryCalloutContainer = styled.section`
  padding-top: 0;
`;

const PrivateEventsInquiryCalloutGrid = styled.div`
  ${mixins.wrap}
  ${mixins.grid}
  background-color: ${color.foreground};
  border-radius: ${border.radius};
  overflow: hidden;
`;

const PrivateEventsInquiryCalloutGridContent = styled.div`
  padding: 6em;

  @media ${media.primary} {
    padding: 3em;
  }

  @media ${media.secondary} {
    padding: 2.65em;
  }

  @media ${media.smallPhone} {
    padding: 2em;
  }

  .cta {
    margin-bottom: 0;
  }
`;

const GridImage = styled.div`
  background-image: url(https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-tables-with-artwork.webp)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media ${media.secondary} {
		padding-top: 75%;
  }
`;

function PrivateEventsInquiryCallout() {
  return (
    <PrivateEventsInquiryCalloutContainer>
      <PrivateEventsInquiryCalloutGrid>
        <PrivateEventsInquiryCalloutGridContent>
          <div className="title dark">Get Started</div>

          <h4 className="dark">Let's start planning your event.</h4>
          <p className="dark">
            Let's kick things off with a consultation. Our staff will walk you
            through the process, help you select a menu and pick out the perfect
            space. From intimate dinners in one of our private rooms to business
            parties on our skydeck, when you book an event with Poco Piatti
            you're in for an unforgettable experience. Call{' '}
            <a href="tel:419-931-0281">(419) 931-0281</a> ext. #4 and we will
            answer all of your questions. You can also email us directly at
            pocopiattiparty@gmail.
          </p>
          <LinkExternal
            text="Email"
            href="mailto:pocopiattiparty@gmail.com"
            title="Email Poco Piatti."
            filled
          />
        </PrivateEventsInquiryCalloutGridContent>
        <GridImage />
      </PrivateEventsInquiryCalloutGrid>
    </PrivateEventsInquiryCalloutContainer>
  );
}

PrivateEventsInquiryCallout.propTypes = {};

export default PrivateEventsInquiryCallout;
