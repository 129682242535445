const faqData = [
  {
    question: 'Dietary Restrictions',
    answer:
      'We accommodate most dietary needs like gluten free, vegetarian, and vegan. Ask for our gluten free menu. Our meat is Halal.',
  },
  {
    question: 'Locations',
    answer:
      'We have three locations for dining: Levis Commons in Perrysburg, Central Avenue in Sylvania, and downtown Toledo at Hensville. We also have a private events venue in downtown Toledo.',
  },
  {
    question: 'Reservations & Carryout',
    answer:
      'For reservations and carryout orders call 419-931-0281. Ext. #1 - Catering at all locations. Ext. #2 - Perrysburg location. Ext. #3 - Sylvania location. Ext #4 - Hensville location. Ext. #5 - The Garden by Poco Piatti at Glass City Metropark. Ext. #6 Operations Manager. Or order online for easy online ordering. When visiting us in house, automatic gratuity may be applied to groups of 5 or more.',
  },
  {
    question: 'Bar Options',
    answer: 'We serve beer, wine, cocktails, and our famous sangria!',
  },
  {
    question: 'Kids Menu',
    answer: 'Ask for a cheese or pepperoni pizza.',
  },
  {
    question: 'Wifi',
    answer: 'Complimentary wifi is available.',
  },
  {
    question: 'Gift Certificates',
    answer: 'We offer gift certificates in-store at all our locations.',
  },
];

export default faqData;
