import React from 'react';
import styled from 'styled-components';

// Components
import LinkExternal from '../LinkExternal';

// Styles
import media from '../../styles/media';
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { border, color } = theme;

const OrderCateringCalloutContainer = styled.section`
  padding-top: 0;
`;

const OrderCateringCalloutGrid = styled.div`
  ${mixins.wrap}
  ${mixins.grid}
  background-color: ${color.foreground};
  border-radius: ${border.radius};
  overflow: hidden;
`;

const OrderCateringCalloutGridContent = styled.div`
  padding: 6em;

  @media ${media.primary} {
    padding: 3em;
  }

  @media ${media.secondary} {
    padding: 2.65em;
  }

  @media ${media.smallPhone} {
    padding: 3.5em 2em;
  }

  .cta {
    margin-bottom: 0;
  }
`;

const GridImage = styled.div`
  background-image: url(https://pocopiatti.s3.us-east-2.amazonaws.com/catering/poco-piatti-catering-salads.webp)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media ${media.secondary} {
		padding-top: 75%;
  }
`;

function OrderCateringCallout() {
  return (
    <OrderCateringCalloutContainer>
      <OrderCateringCalloutGrid>
        <OrderCateringCalloutGridContent>
          <div className="title dark">catering</div>
          <h4 className="dark">Poco for large groups</h4>
          <p className="dark">
            Whether you are planning an office party, a special event or a
            wedding, we would love to work with you. To place your catering
            order call us at <a href="tel:419-931-0281">(419) 931-0281</a>and we
            will answer all of your questions. You can also email us directly.
          </p>
          <LinkExternal
            text="Email"
            href="mailto:pocopiattiparty@gmail.com"
            title="Email Poco Piatti."
            filled
          />
        </OrderCateringCalloutGridContent>
        <GridImage />
      </OrderCateringCalloutGrid>
    </OrderCateringCalloutContainer>
  );
}

OrderCateringCallout.propTypes = {};

export default OrderCateringCallout;
