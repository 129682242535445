import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import CateringPackages from '../../components/CateringPackages/index';
import BarPackages from '../../components/BarPackages';
import ALaCarte from '../../components/ALaCarte/index';
import Header from '../../components/Header/index';
import SubPageHero from '../../components/SubPageHero';
import OrderCateringCallout from '../../components/OrderCateringCallout/index';
import Footer from '../../components/Footer/index';
import ImageSlider from '../../components/ImageSlider';

// Images
import CateringImagesData from '../../data/images/catering-images';

function Catering() {
  return (
    <>
      <Helmet>
        <title>Catering | Poco Piatti</title>
        <meta
          name="description"
          content="Mediterranean tapas restaurant and bar."
        />
      </Helmet>
      <Header light absolute />
      <SubPageHero
        title="Catering"
        h1Text={[
          'FULL SERVICE CATERING FOR ',
          <span>memorable</span>,
          ' FOOD ',
          <span>experiences.</span>,
        ]}
        imageUrl="https://pocopiatti.s3.us-east-2.amazonaws.com/catering/poco-piatti-catering-salads.webp"
        light
        copy="We know you love eating at Poco Piatti and we are excited to bring that same experience to you. We can deliver your food directly to your event, or we can provide Curbside Catering pickup by ordering ahead of time."
      />
      <ImageSlider imageData={CateringImagesData} />

      <section className="no-padding-top">
        <div className="inner-wrap">
          <h2>
            <span>Catering</span>
            menus
          </h2>
          <CateringPackages />
          <BarPackages />
          <ALaCarte />
        </div>
      </section>
      <OrderCateringCallout />
      <Footer />
    </>
  );
}

export default Catering;
